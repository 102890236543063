import { graphql } from 'gatsby';
import React, { useMemo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Header from 'src/components/header/Header';
import MetaTags, { PageMetaData } from 'src/components/MetaTags';
import OverviewCardList from 'src/components/overviewCardList/OverviewCardList';
import PaginationControls from 'src/components/paginationControls/PaginationControls';
import { Result } from 'src/components/search/SearchResults';
import allCategory from 'src/helpers/allCategory';
import getLocalizedRoute from 'src/helpers/getLocalizedRoute';
import { fromStrapiToUI } from 'src/helpers/strapiLocales';
import Category from 'src/interfaces/Category';
import PostData from 'src/interfaces/PostData';
import PropsMeta from 'src/interfaces/PropsMeta';
import languageData from 'src/languages';
import * as style from './menu.module.scss';

interface Props extends PropsMeta {
	data: {
		allStrapiPosts: {
			postOverviews: Partial<PostData>[];
		};
		availableCategories: {
			availableCategories: Category[];
		};
		currentCategoryTranslations: {
			currentCategoryTranslations: CategoryTranslation[];
		};
		allResults: {
			allResults: Result[];
		};
	};
	pageContext: {
		currentCategory: Category;
	};
}

interface CategoryTranslation extends Category {
	posts: { id: number }[];
}

const translations = defineMessages({
	metaTitle: {
		id: 'menu.metaTitle',
		defaultMessage: 'SpectroCoin Blog',
	},
	metaDescription: {
		id: 'menu.metaDescription',
		defaultMessage:
			'A single place for everything related to SpectroCoin – from the latest news to fascinating articles about various cryptocurrency topics.',
	},
});

interface Props {
	query: string;
	allResults: Result[];
}

interface QueryResult extends Result {
	matches: number;
}

const showAllTemplate: React.FC<Props> = ({ data, pageContext, location }) => {
	const { locale, formatMessage } = useIntl();
	const { currentCategory } = pageContext;
	const listRef = useRef<HTMLDivElement>(null);
	const executeScroll = () => listRef?.current?.scrollIntoView();
	const initialState = () => {
		if (location.state) return location.state.inputFeed;
		return '';
	};
	const [query, setQuery] = useState<string>(initialState);
	const queryPhrase = () => {
		if (query !== undefined) {
			return [query.toLowerCase()];
		}
		return [''];
	};
	const metaData: PageMetaData = {
		title: `${formatMessage(translations.metaTitle)} | SpectroCoin`,
		description: formatMessage(translations.metaDescription),
		og: {
			urlPathname:
				currentCategory.path !== undefined ? getLocalizedRoute(currentCategory.path, locale) : undefined,
		},
	};
	const { availableCategories } = data.availableCategories;
	const { currentCategoryTranslations } = data.currentCategoryTranslations;
	const allResults = data.allResults.allResults.filter((p) => !p.hidden);
	const allAvailableCategories = availableCategories.slice();
	allAvailableCategories.unshift(allCategory(locale));

	const disableSearchResults = true;
	const [currentPage, setCurrentPage] = useState(1);
	const pageSize = 6;

	const contentResults: QueryResult[] = allResults
		.map((r) => {
			let matches = 0;
			const contentToTest = r.content.toLowerCase();
			const excerptToTest = r.excerpt.toLowerCase();

			queryPhrase().forEach((phrase) => {
				matches += contentToTest.split(phrase).length - 1;
				matches += excerptToTest.split(phrase).length - 1;
			});

			return {
				...r,
				matches,
			};
		})
		.filter((r) => r.matches > 0)
		.sort((a, b) => b.matches - a.matches);

	const currentPageOverviewData = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * pageSize;
		const lastPageIndex = firstPageIndex + pageSize;
		return contentResults.slice(firstPageIndex, lastPageIndex);
	}, [currentPage]);

	// Create routes for language switcher
	let navigationRoutes: { locale: string; route: string }[] = [];

	if (currentCategoryTranslations.length > 0) {
		// Not 'All' category
		navigationRoutes = currentCategoryTranslations.map((c) => {
			return {
				locale: fromStrapiToUI(c.locale),
				route: c.posts.length > 0 ? c.path : '', // Also check if the category translation has any posts
			};
		});
		navigationRoutes.push({
			locale: currentCategory.locale,
			route: currentCategory.path,
		});
	} else {
		// 'All' category
		Object.keys(languageData).forEach((key) => {
			navigationRoutes.push({
				locale: languageData[key]?.locale || languageData.Default.locale,
				route: '',
			});
		});
	}

	return (
		<>
			<MetaTags data={metaData} />
			<Header
				categories={allAvailableCategories}
				currentCategory={currentCategory}
				navigationRoutes={navigationRoutes}
				allResults={allResults}
				setQuery={setQuery}
				query={query}
				disableSearchResults={disableSearchResults}
			/>
			<div>
				{contentResults.length > 0 && (
					<>
						<div className={style.cardList} ref={listRef}>
							<OverviewCardList overviews={contentResults} />
						</div>
						<div className={style.pagination}>
							<PaginationControls
								currentPage={currentPage}
								totalCount={currentPageOverviewData.length}
								pageSize={pageSize}
								onPageChange={(page) => {
									setCurrentPage(page);
									executeScroll();
								}}
								siblingCount={2}
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export const query = graphql`
	query ($strapiLocale: String!, $strapiCategoryName: String, $currentCategoryTranslationIDs: [Int]!) {
		allStrapiPosts(
			filter: { locale: { eq: $strapiLocale }, category: { name: { eq: $strapiCategoryName } } }
			sort: { fields: date, order: DESC }
		) {
			postOverviews: nodes {
				category {
					name
				}
				title
				date
				path
				excerpt
				hidden
				thumbnail {
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
				thumbnailAlt
			}
		}
		availableCategories: allStrapiCategories(
			filter: { locale: { eq: $strapiLocale }, posts: { elemMatch: { locale: { eq: $strapiLocale } } } }
		) {
			availableCategories: nodes {
				name
				path
				locale
			}
		}
		currentCategoryTranslations: allStrapiCategories(filter: { strapiId: { in: $currentCategoryTranslationIDs } }) {
			currentCategoryTranslations: nodes {
				name
				path
				locale
				posts {
					id
				}
			}
		}
		allResults: allStrapiPosts(filter: { locale: { eq: $strapiLocale } }, sort: { fields: date, order: DESC }) {
			allResults: nodes {
				category {
					name
				}
				title
				date
				path
				excerpt
				content
				hidden
				thumbnail {
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
				thumbnailAlt
			}
		}
	}
`;

export default showAllTemplate;
