import React from 'react';
import OverviewCard from 'src/components/overviewCard/OverviewCard';
import OverviewData from 'src/interfaces/OverviewData';
import * as style from './OverviewCardList.module.scss';

interface Props {
	overviews: Partial<OverviewData>[];
}

const OverviewCardList: React.FC<Props> = ({ overviews }) => {
	return (
		<div className={style.container}>
			{overviews.map((overview) => {
				return <OverviewCard {...overview} key={overview.path} />;
			})}
		</div>
	);
};

export default OverviewCardList;
