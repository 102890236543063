import React from 'react';
import arrow from 'src/assets/images/vector/pagination-arrow.svg';
import usePagination, { DOTS, PaginationProps } from 'src/hooks/usePagination';
import * as style from './PaginationControls.module.scss';

interface Props extends PaginationProps {
	onPageChange: (arg0: number) => void;
}

const PaginationControls: React.FC<Props> = (props) => {
	const { currentPage, onPageChange } = props;
	const paginationRange = usePagination(props);
	const lastPage = paginationRange[paginationRange.length - 1];

	// If there are less than 2 times in pagination range we shall not render the component
	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	return (
		<div className={style.container}>
			{/* Left navigation arrow */}
			<button
				onClick={onPrevious}
				className={currentPage === 1 ? style.disabledClickablePill : style.clickablePill}
				type="button"
			>
				<div className={style.arrowLeft}>
					<img src={arrow} />
				</div>
			</button>
			{paginationRange.map((pageNumber, key) => {
				// If the pageItem is a DOT, render the DOTS unicode character
				if (pageNumber === DOTS) {
					return (
						<div key={key} className={style.pill}>
							&#8230;
						</div>
					);
				}

				// Render our Page Pills
				return (
					<button
						key={key}
						onClick={() => onPageChange(pageNumber)}
						className={currentPage === pageNumber ? style.activePill : style.clickablePill}
						type="button"
					>
						{pageNumber}
					</button>
				);
			})}
			{/*  Right Navigation arrow */}
			<button
				onClick={onNext}
				className={currentPage === lastPage ? style.disabledClickablePill : style.clickablePill}
				type="button"
			>
				<div className={style.arrowRight}>
					<img src={arrow} />
				</div>
			</button>
		</div>
	);
};

export default PaginationControls;
