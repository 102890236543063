/* eslint-disable react/no-danger */
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LinkWithArrow from 'src/components/linkWithArrow/LinkWithArrow';
import MarkdownRenderer from 'src/components/markdownRenderer/MarkdownRenderer';
import getLocalizedRoute from 'src/helpers/getLocalizedRoute';
import { getPostDate, shortenExcerpt } from 'src/helpers/post';
import OverviewData from 'src/interfaces/OverviewData';
import * as style from './OverviewCard.module.scss';

const OverviewCard: React.FC<Partial<OverviewData>> = ({
	thumbnail,
	thumbnailAlt,
	category,
	title,
	date,
	excerpt,
	path,
}) => {
	const { locale } = useIntl();
	let thumbnailImage;
	if (thumbnail?.localFile) thumbnailImage = getImage(thumbnail.localFile);

	return (
		<div className={style.container}>
			{path && (
				<Link to={getLocalizedRoute(path, locale)} className={style.image}>
					{thumbnailImage && (
						<GatsbyImage image={thumbnailImage} alt={thumbnailAlt || ''} className={style.image} />
					)}
				</Link>
			)}
			<div className={style.textContainer}>
				{category && <a className={style.category}> {category.name.toUpperCase()}</a>}
				<div className={style.title}>
					{path && (
						<Link className={style.gatsbyLink} to={getLocalizedRoute(path, locale)}>
							<h1>{title}</h1>
						</Link>
					)}
				</div>
				{date && <div className={style.date}>{getPostDate(date, locale)}</div>}
				{excerpt && (
					<div className={style.description}>
						<MarkdownRenderer>{shortenExcerpt(excerpt, 100)}</MarkdownRenderer>
					</div>
				)}
				{path && (
					<LinkWithArrow
						href={getLocalizedRoute(path, locale)}
						text={<FormattedMessage id="link.continueReading" defaultMessage="Continue reading" />}
					/>
				)}
			</div>
		</div>
	);
};

export default OverviewCard;
