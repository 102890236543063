// Based on https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
import { useMemo } from 'react';

export interface PaginationProps {
	totalCount: number;
	pageSize: number;
	siblingCount?: number;
	currentPage: number; // 1 index based (not 0)
}

export const DOTS = -1;

const usePagination: (props: PaginationProps) => number[] = ({
	totalCount,
	pageSize,
	siblingCount = 1,
	currentPage,
}) => {
	const range = (start: number, end: number): number[] => {
		const length = end - start + 1;
		return Array.from({ length }, (_, idx) => idx + start);
	};

	const paginationRange = useMemo<number[]>(() => {
		const totalPageCount = Math.ceil(totalCount / pageSize);

		// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
		const totalPageNumbers = siblingCount + 5;

		// Case 1: If the number of pages is less than the page numbers we want to show in our paginationComponent
		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		// Case 2: No left dots to show, but rights dots to be shown
		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = 3 + 2 * siblingCount;
			const leftRange = range(1, leftItemCount);

			return [...leftRange, DOTS, totalPageCount];
		}

		// Case 3: No right dots to show, but left dots to be shown
		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = 3 + 2 * siblingCount;
			const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
			return [firstPageIndex, DOTS, ...rightRange];
		}

		// Case 4: Both left and right dots to be shown
		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}

		return []; // Should not be reachable
	}, [totalCount, pageSize, siblingCount, currentPage]);

	return paginationRange;
};

export default usePagination;
